var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c("c-table", {
              ref: "gridWork",
              attrs: {
                title: "작업계획 목록",
                isTitle: true,
                gridHeightAuto: true,
                columns: _vm.gridWork.columns,
                data: _vm.gridWork.data,
                gridHeight: _vm.gridWork.height,
                editable: _vm.editable && !_vm.disabled && !_vm.apprDisabled,
                hideBottom: true,
                isExcelDown: false,
                filtering: false,
                isFullScreen: false,
                columnSetting: false,
              },
              on: { rowClick: _vm.rowClick },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "q-form",
              { ref: "editForm2" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "작업계획 상세" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-4 col-sm-4 col-md-1 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              label: "항목번호",
                              name: "sortOrder",
                              type: "number",
                            },
                            model: {
                              value: _vm.workdata.sortOrder,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "sortOrder", $$v)
                              },
                              expression: "workdata.sortOrder",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-8 col-sm-8 col-md-5 col-lg-5 col-xl-5",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              label: "작업명",
                              name: "workPlanWorkName",
                            },
                            model: {
                              value: _vm.workdata.workPlanWorkName,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workPlanWorkName", $$v)
                              },
                              expression: "workdata.workPlanWorkName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                        },
                        [
                          _c("c-select", {
                            attrs: {
                              required: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              codeGroupCd: "WO_OPR_TYPE_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "workOprTypeCd",
                              label: "작업제어구분",
                            },
                            on: { datachange: _vm.ChangeWorkOprType },
                            model: {
                              value: _vm.workdata.workOprTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workOprTypeCd", $$v)
                              },
                              expression: "workdata.workOprTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: true,
                              range: true,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              type: "date",
                              label: "작업기간",
                              name: "workDts",
                            },
                            on: { datachange: _vm.setWorkerPeriod },
                            model: {
                              value: _vm.workdata.workDts,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workDts", $$v)
                              },
                              expression: "workdata.workDts",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-4 col-sm-4 col-md-1 col-lg-1 col-xl-1",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              isFlag: true,
                              label: "휴무일포함",
                              name: "workHolidayFlag",
                            },
                            model: {
                              value: _vm.workdata.workHolidayFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workHolidayFlag", $$v)
                              },
                              expression: "workdata.workHolidayFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              codeGroupCd: "SOP_WORK_TYPE_CD",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "작업허가서 구분",
                              name: "workpermitTypes",
                            },
                            model: {
                              value: _vm.workdata.workpermitTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workpermitTypes", $$v)
                              },
                              expression: "workdata.workpermitTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        },
                        [
                          _c("c-checkbox", {
                            attrs: {
                              isArray: false,
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork &&
                                !_vm.apprDisabled,
                              codeGroupCd: "WO_RISK_FACTOR",
                              itemText: "codeName",
                              itemValue: "code",
                              label: "위험요인",
                              name: "workRiskFactors",
                            },
                            model: {
                              value: _vm.workdata.workRiskFactors,
                              callback: function ($$v) {
                                _vm.$set(_vm.workdata, "workRiskFactors", $$v)
                              },
                              expression: "workdata.workRiskFactors",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.workdata.workOprTypeCd !== "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-8 col-sm-8 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-vendor", {
                                attrs: {
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork &&
                                    !_vm.apprDisabled,
                                  label: "협력업체",
                                  name: "vendorCd",
                                },
                                model: {
                                  value: _vm.workdata.vendorCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workdata, "vendorCd", $$v)
                                  },
                                  expression: "workdata.vendorCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.workdata.workOprTypeCd !== "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3",
                            },
                            [
                              _c("c-text", {
                                attrs: {
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork &&
                                    !_vm.apprDisabled,
                                  label: "작업인원",
                                  name: "vendorWorkerCnt",
                                  type: "number",
                                  suffix: "명",
                                },
                                model: {
                                  value: _vm.workdata.vendorWorkerCnt,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.workdata,
                                      "vendorWorkerCnt",
                                      $$v
                                    )
                                  },
                                  expression: "workdata.vendorWorkerCnt",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.workdata.workOprTypeCd === "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-dept", {
                                attrs: {
                                  type: "edit",
                                  isFirstValue: _vm.workUpdateMode,
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork &&
                                    !_vm.apprDisabled,
                                  label: "작업부서",
                                  name: "workDeptCd",
                                },
                                model: {
                                  value: _vm.workdata.workDeptCd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workdata, "workDeptCd", $$v)
                                  },
                                  expression: "workdata.workDeptCd",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.workdata.workOprTypeCd === "WOTC000001"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("c-multi-field", {
                                attrs: {
                                  editable:
                                    _vm.editable &&
                                    !_vm.disabled &&
                                    _vm.selectedWork &&
                                    !_vm.apprDisabled,
                                  userInfo: _vm.userInfo,
                                  isArray: "",
                                  type: "dept_user",
                                  label: "내부작업자",
                                  name: "workUsers",
                                },
                                on: {
                                  "update:userInfo": function ($event) {
                                    _vm.userInfo = $event
                                  },
                                  "update:user-info": function ($event) {
                                    _vm.userInfo = $event
                                  },
                                },
                                model: {
                                  value: _vm.workdata.workUsers,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.workdata, "workUsers", $$v)
                                  },
                                  expression: "workdata.workUsers",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "grid",
                    attrs: {
                      title: "작업계획별 소요자재",
                      columns: _vm.grid.columns,
                      data: _vm.workdata.materialList,
                      gridHeight: _vm.grid.height,
                      editable:
                        _vm.editable && !_vm.disabled && _vm.selectedWork,
                      isTitle: true,
                      gridHeightAuto: true,
                      hideBottom: true,
                      isExcelDown: false,
                      filtering: false,
                      isFullScreen: false,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "materialCd",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props }) {
                          return [
                            [
                              _c("q-btn", {
                                attrs: {
                                  flat: "",
                                  size: "12px",
                                  color: "orange",
                                  icon: "chevron_right",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowClickDetail(
                                      props.row,
                                      props.pageIndex
                                    )
                                  },
                                },
                              }),
                            ],
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workResultId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "BOM", icon: "add" },
                                  on: { btnClicked: _vm.addRowBom },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workResultId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "자재", icon: "add" },
                                  on: { btnClicked: _vm.addRow },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workResultId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "remove" },
                                  on: { btnClicked: _vm.removeRow },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "q-dialog",
              {
                attrs: { position: _vm.positionDetail },
                model: {
                  value: _vm.dialogDetail,
                  callback: function ($$v) {
                    _vm.dialogDetail = $$v
                  },
                  expression: "dialogDetail",
                },
              },
              [
                _c(
                  "q-card",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("q-linear-progress", {
                      attrs: { value: 1, color: "pink" },
                    }),
                    _c("q-card-section", { staticClass: "row" }, [
                      _c("div", {
                        staticClass: "col-12 text-weight-bold-dailog",
                        domProps: {
                          textContent: _vm._s(_vm.selectedRow.materialName),
                        },
                      }),
                      _c("div", { staticClass: "col-2" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("자재No"),
                        ]),
                      ]),
                      _c("div", {
                        staticClass: "col-10",
                        domProps: {
                          textContent: _vm._s(_vm.selectedRow.materialNo),
                        },
                      }),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("용도"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "",
                              name: "materialDesc",
                            },
                            on: { dataChange: _vm.tableDataChangeMaterial },
                            model: {
                              value: _vm.selectedRow.materialDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow, "materialDesc", $$v)
                              },
                              expression: "selectedRow.materialDesc",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("단가"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              type: "number",
                              label: "",
                              name: "unitPrice",
                            },
                            on: { dataChange: _vm.tableDataChangeMaterial },
                            model: {
                              value: _vm.selectedRow.unitPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow, "unitPrice", $$v)
                              },
                              expression: "selectedRow.unitPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("수량"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              type: "number",
                              label: "",
                              name: "unitEa",
                            },
                            on: { dataChange: _vm.tableDataChangeMaterial },
                            model: {
                              value: _vm.selectedRow.unitEa,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow, "unitEa", $$v)
                              },
                              expression: "selectedRow.unitEa",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("소계"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              label: "",
                              type: "number",
                              name: "priceCost",
                            },
                            on: { dataChange: _vm.tableDataChangeMaterial },
                            model: {
                              value: _vm.selectedRow.priceCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow, "priceCost", $$v)
                              },
                              expression: "selectedRow.priceCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 mini-dailog-btn-impr" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "닫기",
                                  icon: "close",
                                },
                                on: { btnClicked: _vm.dialogClose },
                              }),
                              _vm.editable && !_vm.disabled && _vm.selectedWork
                                ? _c("c-btn", {
                                    attrs: {
                                      showLoading: false,
                                      label: "적용",
                                      icon: "check",
                                      color: "amber-7",
                                    },
                                    on: { btnClicked: _vm.dialogSubmit },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "grid2",
                    attrs: {
                      title: "작업계획별 서비스(외주)",
                      columns: _vm.grid2.columns,
                      data: _vm.workdata.serviceList,
                      gridHeight: _vm.grid2.height,
                      editable:
                        _vm.editable && !_vm.disabled && _vm.selectedWork,
                      isTitle: true,
                      gridHeightAuto: true,
                      hideBottom: true,
                      isExcelDown: false,
                      filtering: false,
                      isFullScreen: false,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "vendorCd",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props }) {
                          return [
                            [
                              _c("q-btn", {
                                attrs: {
                                  flat: "",
                                  size: "12px",
                                  color: "orange",
                                  icon: "chevron_right",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.rowClickDetail2(
                                      props.row,
                                      props.pageIndex
                                    )
                                  },
                                },
                              }),
                            ],
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workResultId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "add" },
                                  on: { btnClicked: _vm.addRowService },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            !_vm.disabled &&
                            Boolean(_vm.param.workResultId) &&
                            _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "remove" },
                                  on: { btnClicked: _vm.removeRowService },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "q-dialog",
              {
                attrs: { position: _vm.positionDetail2 },
                model: {
                  value: _vm.dialogDetail2,
                  callback: function ($$v) {
                    _vm.dialogDetail2 = $$v
                  },
                  expression: "dialogDetail2",
                },
              },
              [
                _c(
                  "q-card",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("q-linear-progress", {
                      attrs: { value: 1, color: "pink" },
                    }),
                    _c("q-card-section", { staticClass: "row" }, [
                      _c("div", {
                        staticClass: "col-12 text-weight-bold-dailog",
                        domProps: {
                          textContent: _vm._s(_vm.selectedRow2.vendorName),
                        },
                      }),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("원가요소"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-select", {
                            attrs: {
                              editable: false,
                              codeGroupCd: "WO_COST_FACOTR_CD",
                              type: "edit",
                              itemText: "codeName",
                              itemValue: "code",
                              name: "costFactorCd",
                              label: "원가요소",
                            },
                            model: {
                              value: _vm.selectedRow2.costFactorCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "costFactorCd", $$v)
                              },
                              expression: "selectedRow2.costFactorCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("작업인원"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "",
                              type: "number",
                              name: "manCnt",
                            },
                            on: { dataChange: _vm.tableDataChangeService },
                            model: {
                              value: _vm.selectedRow2.manCnt,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "manCnt", $$v)
                              },
                              expression: "selectedRow2.manCnt",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("인력단가"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "",
                              type: "number",
                              name: "priceCost",
                            },
                            on: { dataChange: _vm.tableDataChangeService },
                            model: {
                              value: _vm.selectedRow2.priceCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "priceCost", $$v)
                              },
                              expression: "selectedRow2.priceCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("인건비"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              label: "",
                              type: "number",
                              name: "manCost",
                            },
                            on: { dataChange: _vm.tableDataChangeService2 },
                            model: {
                              value: _vm.selectedRow2.manCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "manCost", $$v)
                              },
                              expression: "selectedRow2.manCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("자재비"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable:
                                _vm.editable &&
                                !_vm.disabled &&
                                _vm.selectedWork,
                              type: "number",
                              label: "",
                              name: "materialCost",
                            },
                            on: { dataChange: _vm.tableDataChangeService2 },
                            model: {
                              value: _vm.selectedRow2.materialCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "materialCost", $$v)
                              },
                              expression: "selectedRow2.materialCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col-4" }, [
                        _c("div", { staticClass: "mini-dailog-title" }, [
                          _vm._v("합계"),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: false,
                              type: "number",
                              label: "",
                              name: "sumCost",
                            },
                            on: { dataChange: _vm.tableDataChangeService },
                            model: {
                              value: _vm.selectedRow2.sumCost,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedRow2, "sumCost", $$v)
                              },
                              expression: "selectedRow2.sumCost",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 mini-dailog-btn-impr" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "닫기",
                                  icon: "close",
                                },
                                on: { btnClicked: _vm.dialogClose2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "toolGrid",
                    attrs: {
                      title: "공기구",
                      columns: _vm.toolGrid.columns,
                      data: _vm.workdata.toolList,
                      gridHeight: _vm.toolGrid.height,
                      editable: _vm.editable && _vm.selectedWork,
                      isTitle: true,
                      gridHeightAuto: true,
                      hideBottom: true,
                      isExcelDown: false,
                      filtering: false,
                      isFullScreen: false,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "materialCd",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled && _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "add" },
                                  on: { btnClicked: _vm.addRowTool },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled && _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "remove" },
                                  on: { btnClicked: _vm.removeRowTool },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "workerGrid",
                    attrs: {
                      title: "작업자 할당시간",
                      columns: _vm.workerGrid.columns,
                      data: _vm.workdata.workerList,
                      gridHeight: _vm.workerGrid.height,
                      editable: _vm.editable && _vm.selectedWork,
                      isTitle: true,
                      gridHeightAuto: true,
                      hideBottom: true,
                      isExcelDown: false,
                      filtering: false,
                      isFullScreen: false,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "mdmSwsWorkerId",
                    },
                    on: { "table-data-change": _vm.tableDataChangeWorker },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable && !_vm.disabled && _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "선택", icon: "add" },
                                  on: { btnClicked: _vm.addRowWorker },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled && _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "직접", icon: "add" },
                                  on: { btnClicked: _vm.addRowWorker2 },
                                })
                              : _vm._e(),
                            _vm.editable && !_vm.disabled && _vm.selectedWork
                              ? _c("c-btn", {
                                  attrs: { label: "", icon: "remove" },
                                  on: { btnClicked: _vm.removeRowWorker },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        _c("div", { staticClass: "popup-bottom-bar" }, [
          _c(
            "div",
            { staticClass: "popup-bottom-bar-btngroup" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  !_vm.disabled &&
                  Boolean(_vm.param.workResultId) &&
                  !_vm.apprDisabled
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addRowWork },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  Boolean(_vm.param.workResultId) &&
                  _vm.selectedWork &&
                  _vm.workUpdateMode &&
                  !_vm.apprDisabled
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "remove" },
                        on: { btnClicked: _vm.removeRowWork },
                      })
                    : _vm._e(),
                  _vm.editable &&
                  !_vm.disabled &&
                  Boolean(_vm.param.workResultId) &&
                  _vm.selectedWork &&
                  !_vm.apprDisabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveWorkUrl,
                          isSubmit: _vm.isSave2,
                          param: _vm.workdata,
                          mappingType: _vm.mappingType2,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveWork,
                          btnCallback: _vm.saveCallbackWork,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "popup-bottom-bar-close" },
            [
              _c("q-btn", {
                attrs: { flat: "", color: "gray", icon: "arrow_back" },
                on: { click: _vm.closePopUps },
              }),
            ],
            1
          ),
        ]),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }